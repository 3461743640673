<div class="text-center">
  <div [ngSwitch]="messageType">

    <!--Confirmation-->
    <div *ngSwitchCase="1">
      <div class="bodyMessage">
        <div>
          <mat-icon>warning</mat-icon>
        </div>
        <div>
          <h3 class="tittle-message">{{ title }}</h3>
        </div>
        <div class="col">
          <p class="message">{{ message }}</p>
        </div>
      </div>
      <div class="col">
        <button mat-raised-button class="btn round margin-right-08" [mat-dialog-close]="false">
          No
        </button>
        <button mat-raised-button color="accent" type="submit" class="btn btn-primary round" [mat-dialog-close]="true">
          Si
        </button>
      </div>
    </div>

    <!--Error-->
    <div *ngSwitchCase="2">
      <div class="bodyMessage">
        <div>
          <mat-icon class="iconError">highlight_off</mat-icon>
        </div>
        <div>
          <p class="message">{{ message }}</p>
        </div>
      </div>
      <div class="col">
        <button mat-raised-button color="accent" type="submit" class="btn btn-primary round" [mat-dialog-close]="true">
          Aceptar
        </button>
      </div>
    </div>

    <!--Delete-->
    <div *ngSwitchCase="3">
      <div class="bodyMessage">
        <div>
          <mat-icon class="delete-color">delete_forever</mat-icon>
        </div>
        <div>
          <h3 class="tittle-message delete-color">{{ title }}</h3>
        </div>
        <div>
          <p class="message">{{ message }}</p>
        </div>
      </div>
      <div class="col">
        <button mat-raised-button class="btn round margin-right-08" [mat-dialog-close]="false">
          No
        </button>
        <button mat-raised-button color="accent" type="submit" class="btn btn-primary round" [mat-dialog-close]="true">
          Si
        </button>
      </div>
    </div>

    <!--Informative-->
    <div *ngSwitchCase="4">
      <div class="bodyMessage">
        <div>
          <mat-icon>info</mat-icon>
        </div>
        <div>
          <h3 class="tittle-message">{{ title }}</h3>
        </div>
        <div>
          <p class="message">{{ message }}</p>
        </div>
      </div>
      <div class="col">
        <button mat-raised-button color="accent" type="submit" class="btn btn-primary round" [mat-dialog-close]="true">
          Aceptar
        </button>
      </div>
    </div>

    <!--Correct-->
    <div *ngSwitchCase="5">
      <div class="display-flex">
        <mat-icon class="iconCorrect">check_circle</mat-icon>
        <p class="messageCorrect">{{message}}</p>
      </div>
      <div class="col">
        <button mat-raised-button color="accent" type="submit" class="btn btn-primary round" [mat-dialog-close]="true">
          Aceptar
        </button>
      </div>
    </div>

    <div *ngSwitchCase="6">
      <div class="bodyMessage">
        <div>
          <mat-icon class="icon-warning">warning</mat-icon>
        </div>
        <div>
          <h3 class="tittle-message warning-color">{{ title }}</h3>
        </div>
        <div>
          <p class="message">{{ message }}</p>
        </div>
      </div>
      <div class="col">
        <button mat-raised-button color="accent" type="submit" class="btn btn-primary round" [mat-dialog-close]="true">
          Aceptar
        </button>
      </div>
    </div>

    <div *ngSwitchCase="7">
      <div class="bodyMessage">
        <div>
          <mat-icon>warning</mat-icon>
        </div>
        <div>
          <h3 class="tittle-message">{{ title }}</h3>
        </div>
        <div class="col">
          <p class="message">{{ message }}</p>
          <p class="message" style="color: red">{{ messageRed }}</p>
        </div>
      </div>
      <div class="col">
        <button mat-raised-button class="btn round margin-right-08" [mat-dialog-close]="false">
          No
        </button>
        <button mat-raised-button color="accent" type="submit" class="btn btn-primary round" [mat-dialog-close]="true">
          Si
        </button>
      </div>
    </div>
    <div *ngSwitchDefault>
      <mat-icon>outlet</mat-icon>
    </div>
  </div>
</div>
